import { TeamPlayerMap } from '../types/common';
import { Game } from '../types/game';
import { GamePreview } from '../types/scoreboard';
import { Information } from '../types/player';
import { teamIds } from './teamsData';
import { formatGameTime } from './dateTime';

export const generateTeamPlayerMap = (): TeamPlayerMap => {
  return Object.keys(teamIds).reduce((acc, team) => {
    acc[team] = [];
    return acc;
  }, {} as TeamPlayerMap);
};

export const parseTimeStamp = (minutes: string): string => {
  const minArray = minutes.split(/[TM.]+/);
  return `${Number(minArray[1])}:${minArray[2]}`;
};

export const parseMinutes = (minutes: string): string => {
  const minArray = minutes.split(/[TM.]+/);
  return `${Number(minArray[1])}`;
};

export const parsePlusMinus = (plusMinus: string): string => {
  return plusMinus[0] === '-' ? plusMinus : `+${plusMinus}`;
};

export const parseQuarter = (quarter: number): string => {
  if (quarter < 5) {
    return `${quarter}Q`;
  }
  return `${quarter === 5 ? '' : quarter - 4}OT`;
};

export const parseQuarterFull = (quarter: number): string => {
  switch (quarter) {
    case 1:
      return '1st Quarter';
    case 2:
      return '2nd Quarter';
    case 3:
      return '3rd Quarter';
    case 4:
      return '4th Quarter';
    default:
      return `Overtime ${quarter === 5 ? '' : quarter - 4}`;
  }
};

export const hasGameEnded = (gameData: Game | GamePreview): boolean => {
  return Boolean(gameData && gameData.gameStatus === 3);
};

export const hasGameStarted = (gameData: Game | GamePreview): boolean => {
  return (
    new Date() > new Date(gameData.gameEt) &&
    (gameData.period > 0 || hasGameEnded(gameData))
  );
};

export const parseGamePreviewStatus = (
  gameData: Game | GamePreview
): string => {
  const { gameStatusText, gameTimeUTC } = gameData;
  return gameStatusText.includes('Final') || gameStatusText.includes('Halftime')
    ? gameStatusText
    : gameData.period === 0 // 0 is pregame
    ? formatGameTime(gameTimeUTC)
    : gameStatusText.includes('End')
    ? `${gameStatusText.slice(0, 8)}Q`
    : gameStatusText.includes('OT')
    ? `${gameData.gameClock} OT${
        gameStatusText[0] === '1' ? '' : gameStatusText[0]
      }`
    : `${gameData.gameClock} ${gameStatusText[0]}Q`;
};

const reverseQuarter = (quarter: string): string => {
  return quarter[1] + quarter[0];
};

export const parseGameStatus = (gameData: Game | GamePreview): string => {
  const { gameStatusText } = gameData;
  const gameStatusArray = gameStatusText.split(' ');
  return gameStatusText.toLowerCase().includes('final')
    ? gameStatusText
    : gameStatusText.toLowerCase().includes('end')
    ? `End of ${reverseQuarter(gameStatusText.trim().slice(-2))}`
    : gameStatusText.toLowerCase().includes('half')
    ? 'Halftime'
    : `${gameStatusArray[1]} ${
        gameStatusText.includes('OT')
          ? gameStatusArray[0]
          : reverseQuarter(gameStatusArray[0])
      }`;
};

export const getTeamRecord = (
  gameData: GamePreview,
  isHome: boolean
): string => {
  return `${isHome ? gameData.homeTeam.wins : gameData.awayTeam.wins}-${
    isHome ? gameData.homeTeam.losses : gameData.awayTeam.losses
  }`;
};

export const getAverageStat = (total: number, games: number): number => {
  return Math.round((total / games) * 10) / 10;
};

export const calculateAge = (birthday: string): number => {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatPlayerInfo = (information: Information): string => {
  return information.pos
    ? `${information.pos.replace('-', '/')} \u00B7 ${
        teamIds[information.teamId][0]
      } \u00B7 #${information.jersey}`
    : '';
};

export const formatStat = (teamStat: string): string => {
  return String(Number(teamStat).toFixed(1));
};
