import React from 'react';
import { useGameData, usePreviewData } from '../utils/fetchers';
import { GameProps } from '../types/common';
import {
  hasGameEnded,
  hasGameStarted,
  parseGameStatus,
} from '../utils/dataParsers';
import TeamPreview from './TeamPreview';
import { teamIds } from '../utils/teamsData';
import { ScoreWrapper, Score, GameStatus } from './Score';
import styled from 'styled-components';
import { theme } from '../utils/theme';
import Container from './Container';
import ScoreTable from './ScoreTable';
import Loader from './Loader';
import { formatDate, formatGameTime, getNBADate } from '../utils/dateTime';

const StatWrapper = styled(Container)<{ large?: boolean }>`
  display: flex;
  padding: ${(props) => (props.large ? '16px 0' : '')};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 28px;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    margin: 0 16px;
  }
`;

const LoadingContainer = styled.div`
  margin-bottom: 16px;
`;

const Status = styled(GameStatus)`
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    width: 40px;
    display: flex;
    justify-content: center;
  }
`;

const Wrapper = styled(ScoreWrapper)`
  align-items: center;
`;

interface StatOverviewProps extends GameProps {
  compact?: boolean;
  large?: boolean;
  pregame?: boolean;
  windowWidth: number | undefined;
  awayTeamRecord: string;
  homeTeamRecord: string;
}

const StatOverview: React.FC<StatOverviewProps> = ({
  compact,
  large,
  pregame,
  windowWidth,
  awayTeamRecord,
  homeTeamRecord,
  gameId,
}) => {
  const { gameData, isLoading: isGameDataLoading } = useGameData(gameId);
  const { previewData, isLoading: isPreviewLoading } = usePreviewData(gameId);

  if ((!pregame && isGameDataLoading) || (pregame && isPreviewLoading))
    return (
      <>
        <Container margin>
          <LoadingContainer>
            <Loader instances={1} height={pregame ? 76 : 71} radius={4} />
          </LoadingContainer>
        </Container>
      </>
    );

  const isMobile = Boolean(
    windowWidth && windowWidth < theme.viewports.mobile.max
  );

  const isVisitorLoser =
    hasGameEnded(gameData) &&
    Number(gameData.awayTeam.score) < Number(gameData.homeTeam.score);

  const isHomeLoser =
    hasGameEnded(gameData) &&
    Number(gameData.awayTeam.score) > Number(gameData.homeTeam.score);

  const nameIndex = isMobile ? 2 : 0;
  const tableIndex = isMobile ? 0 : 2;

  const LineScore = !compact && hasGameStarted(gameData) && (
    <ScoreTable
      awayTeamName={teamIds[gameData.awayTeam.teamId][tableIndex]}
      awayTeamLineScore={gameData.awayTeam.periods}
      homeTeamName={teamIds[gameData.homeTeam.teamId][tableIndex]}
      homeTeamLineScore={gameData.homeTeam.periods}
    />
  );

  const awayTeamId = pregame
    ? previewData.awayTeam.teamId
    : gameData.awayTeam.teamId;
  const homeTeamId = pregame
    ? previewData.homeTeam.teamId
    : gameData.homeTeam.teamId;

  return (
    <>
      <StatWrapper large={large} margin>
        <TeamPreview
          teamId={awayTeamId}
          teamName={teamIds[awayTeamId][nameIndex]}
          teamRecord={awayTeamRecord}
          isHome={false}
          isLoser={isVisitorLoser}
          isMobile={isMobile}
          horizontal
        />
        <Wrapper>
          <Score isLoser={isVisitorLoser}>
            {hasGameStarted(gameData) && gameData.awayTeam.score}
          </Score>
          <StatusContainer>
            <Status
              isLive={
                !pregame && hasGameStarted(gameData) && !hasGameEnded(gameData)
              }
            >
              {pregame
                ? `${formatDate(
                    getNBADate(new Date(previewData.gameTimeUTC))
                  )} at ${formatGameTime(previewData.gameTimeUTC)}`
                : parseGameStatus(gameData)}
            </Status>
            {!isMobile && LineScore}
          </StatusContainer>
          <Score isLoser={isHomeLoser}>
            {hasGameStarted(gameData) && gameData.homeTeam.score}
          </Score>
        </Wrapper>
        <TeamPreview
          teamId={homeTeamId}
          teamName={teamIds[homeTeamId][nameIndex]}
          teamRecord={homeTeamRecord}
          isHome={true}
          isLoser={isHomeLoser}
          isMobile={isMobile}
          horizontal
        />
      </StatWrapper>
      {isMobile && <Container margin>{LineScore}</Container>}
    </>
  );
};

export default StatOverview;
