import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GameProps } from '../types/common';
import { Player } from '../types/game';
import { parseMinutes, parsePlusMinus } from '../utils/dataParsers';
import { useGameData } from '../utils/fetchers';
import PlayerPhoto from './PlayerPhoto';
import PlayerStatus from './PlayerStatus';
import Stat from './Stat';
import StatContainer from './StatContainer';
import { SmallText } from './Typography';

const PlayerPreviewContainer = styled.div`
  background: ${(props) => props.theme.colors.gray[0]};
  border-top: 1px solid ${(props) => props.theme.colors.gray[2]};
  width: 100%;
`;

const PlayerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;

const PlayerInfo = styled(SmallText)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
  span {
    color: ${(props) => props.theme.colors.gray[5]};
  }
`;

interface PlayerPreviewProps extends GameProps {
  personId: number;
  displayIsOnCourt: boolean;
}

const PlayerPreview: React.FC<PlayerPreviewProps> = ({
  personId,
  gameId,
  displayIsOnCourt,
}) => {
  const { gameData, isLoading } = useGameData(gameId);

  const playerData =
    gameData &&
    gameData.homeTeam &&
    gameData.awayTeam &&
    gameData.awayTeam.players &&
    gameData.homeTeam.players &&
    gameData.awayTeam.players
      .concat(gameData.homeTeam.players)
      .filter((activePlayer: Player) => activePlayer.personId === personId)[0];

  if (isLoading || !playerData || playerData.notPlayingDescription)
    return <></>;

  return (
    <PlayerPreviewContainer>
      <Link to={`/player/${personId}`}>
        <PlayerInfoContainer>
          <PlayerPhoto
            personId={personId}
            firstName={playerData.firstName}
            lastName={playerData.familyName}
          />
          <PlayerInfo>
            <h3>
              {playerData.firstName} {playerData.familyName}
            </h3>
            <span>
              {parseMinutes(playerData.statistics.minutes)} min,{' '}
              {playerData.statistics.foulsPersonal} PF,{' '}
              {parsePlusMinus(String(playerData.statistics.plusMinusPoints))}
            </span>
          </PlayerInfo>
          {displayIsOnCourt && (
            <PlayerStatus isOnCourt={playerData.oncourt === '1'} />
          )}
        </PlayerInfoContainer>
        <StatContainer>
          <Stat label="pts" value={playerData.statistics.points} />
          <Stat label="reb" value={playerData.statistics.reboundsTotal} />
          <Stat label="ast" value={playerData.statistics.assists} />
          <Stat label="stl" value={playerData.statistics.steals} />
          <Stat label="blk" value={playerData.statistics.blocks} />
          <Stat
            label="fg"
            value={`${playerData.statistics.fieldGoalsMade}/${playerData.statistics.fieldGoalsAttempted}`}
          />
          <Stat
            label="3pt"
            value={`${playerData.statistics.threePointersMade}/${playerData.statistics.threePointersAttempted}`}
          />
          <Stat
            label="ft"
            value={`${playerData.statistics.freeThrowsMade}/${playerData.statistics.freeThrowsAttempted}`}
          />
        </StatContainer>
      </Link>
    </PlayerPreviewContainer>
  );
};

export default PlayerPreview;
