import React from 'react';
import styled from 'styled-components';
import { GameProps } from '../types/common';
import { useGameData, usePlays } from '../utils/fetchers';
import Container from './Container';
import PlaysTable from './PlaysTable';
import CardContainer from './CardContainer';
import Loader from './Loader';

const PlaysContainer = styled(Container)`
  margin-top: 20px;
  padding-bottom: 40px;
`;

const PlaysCardContainer = styled(CardContainer)`
  padding-top: 0;
  padding-bottom: 0;
`;

const PlayByPlay: React.FC<GameProps> = ({ gameId }) => {
  const { gameData } = useGameData(gameId);
  const { plays, isLoading } = usePlays(gameId);

  const { period } = gameData;

  if (isLoading)
    return (
      <PlaysContainer>
        <CardContainer>
          <Loader height={28} instances={20} radius={4} />
        </CardContainer>
      </PlaysContainer>
    );

  return (
    <PlaysContainer>
      <PlaysCardContainer>
        {[...Array(period)].map((el, index: number) => (
          <PlaysTable
            key={index}
            gameId={gameId}
            period={period - index}
            plays={plays.filter((play) => play.period === period - index)}
          />
        ))}
      </PlaysCardContainer>
    </PlaysContainer>
  );
};

export default PlayByPlay;
