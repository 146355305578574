import { createGlobalStyle } from 'styled-components';
import { Theme } from '../utils/theme';

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
    /* Font imports */
    @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("/assets/fonts/Inter-Regular.woff2?v=3.18") format("woff2"),
        url("Inter-Regular.woff?v=3.18") format("woff");
    }

    @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("/assets/fonts/Inter-SemiBold.woff2?v=3.18") format("woff2"),
        url("Inter-SemiBold.woff?v=3.18") format("woff");
    }

    @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("/assets/fonts/Inter-Bold.woff2?v=3.18") format("woff2"),
        url("Inter-Bold.woff?v=3.18") format("woff");
    }

    /* Reset */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: middle;
    }

    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* General styles */
    html {
        -webkit-text-size-adjust: 100%;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    body {
        margin: 0;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: ${(props) => props.theme.typography.medium.fontSize}px;
        line-height: 1;
        color: ${(props) => props.theme.colors.black};
    }

    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.black};
    }

    h1 {
        font-size: ${(props) => props.theme.typography.xlarge.fontSize}px;
        line-height: ${(props) => props.theme.typography.xlarge.lineHeight}px;
        font-weight: 700;
    }

    h2 {
        font-size: ${(props) => props.theme.typography.large.fontSize}px;
        line-height: ${(props) => props.theme.typography.large.lineHeight}px;
        letter-spacing: -0.01em;
        font-weight: 600;
    }

    h3 {
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 0px;
        font-size: ${(props) => props.theme.typography.medium.fontSize}px;
        line-height: ${(props) => props.theme.typography.medium.lineHeight}px;
    }

    img {
        pointer-events: none;
    }
`;

export default GlobalStyle;
