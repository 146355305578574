import styled from 'styled-components';

export const GameStatus = styled.span<{ isLive: boolean }>`
  font-size: ${(props) => props.theme.typography.small.fontSize}px;
  line-height: ${(props) => props.theme.typography.small.lineHeight}px;
  color: ${(props) =>
    props.isLive ? props.theme.colors.green : props.theme.colors.gray[5]};
  font-weight: 400;
  margin-top: 12px;
  white-space: nowrap;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const Score = styled.h1<{ isLoser: boolean }>`
  min-width: 64px;
  text-align: center;
  color: ${(props) => (props.isLoser ? props.theme.colors.gray[4] : '')};
`;

export const Dash = styled.h1`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.gray[4]};
`;
