import React from 'react';
import styled from 'styled-components';
import { theme } from '../utils/theme';
import Table from './Table';

const TableContainer = styled.div`
  margin-top: 16px;
  position: relative;
`;

const KeyContainer = styled.div`
  background: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 0;
`;

const ValueContainer = styled.div`
  overflow-x: scroll;
`;

interface ScrollableTableProps {
  keys: React.ReactElement;
  values: React.ReactElement;
  windowWidth: number | undefined;
  desktopWidth?: number;
  mobileWidth?: number;
}

const ScrollableTable: React.FC<ScrollableTableProps> = ({
  keys,
  values,
  windowWidth,
  desktopWidth,
  mobileWidth,
}) => {
  const isMobile = Boolean(
    windowWidth && windowWidth < theme.viewports.mobile.max
  );
  return (
    <TableContainer>
      {isMobile && (
        <KeyContainer>
          <Table
            cellPadding={0}
            cellSpacing={0}
            desktopWidth={desktopWidth}
            mobileWidth={mobileWidth}
          >
            {keys}
          </Table>
        </KeyContainer>
      )}
      <ValueContainer>
        <Table
          cellPadding={0}
          cellSpacing={0}
          desktopWidth={desktopWidth}
          mobileWidth={mobileWidth}
        >
          {values}
        </Table>
      </ValueContainer>
    </TableContainer>
  );
};

export default ScrollableTable;
