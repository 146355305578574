import React from 'react';
import styled from 'styled-components';
import { SmallText } from './Typography';

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusCircle = styled.div<{ isOnCourt: boolean }>`
  background: ${(props) =>
    props.isOnCourt ? props.theme.colors.green : props.theme.colors.gray[5]};
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

const StatusLabel = styled(SmallText)<{ isOnCourt: boolean }>`
  margin-left: 4px;
  color: ${(props) =>
    props.isOnCourt ? props.theme.colors.green : props.theme.colors.gray[5]};
`;

interface PlayerStatusProps {
  isOnCourt: boolean;
}

const PlayerStatus: React.FC<PlayerStatusProps> = ({ isOnCourt }) => {
  return (
    <StatusContainer>
      <StatusCircle isOnCourt={isOnCourt} />
      <StatusLabel isOnCourt={isOnCourt}>
        {isOnCourt ? 'On Court' : 'On Bench'}
      </StatusLabel>
    </StatusContainer>
  );
};

export default PlayerStatus;
