import styled from 'styled-components';

const Container = styled.div<{ small?: boolean; margin?: boolean }>`
  max-width: ${(props) =>
    props.small
      ? props.theme.sizes.maxWidthSmall
      : props.theme.sizes.maxWidthLarge}px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    width: ${(props) => (props.margin ? 'calc(100vw - 24px)' : '')};
  }
`;

export default Container;
