import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import BoxScore from '../components/BoxScore';
import StatOverview from '../components/StatOverview';
import styled from 'styled-components';

import { useEffect } from 'react';
import { useGameData, usePreviewData } from '../utils/fetchers';
import TeamStats from '../components/TeamStats';
import { useWindowSize } from '../hooks/useWindowSize';
import Container from '../components/Container';
import Tabs from '../components/Tabs';
import BackButton from '../components/BackButton';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { teamIds } from '../utils/teamsData';
import PreGamePreview from '../components/PreGamePreview';
import PlayByPlay from '../components/PlayByPlay';

const GamePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.gray[0]};
  min-height: 100vh;
`;

const GameHeader = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
`;

const StatOverviewWrapper = styled(GameHeader)<{ show: boolean }>`
  top: ${(props) => (props.show ? '-76px' : '0px')};
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  transition: all 0.25s ease;
`;

const GamePage: React.FC = () => {
  const params = useParams();
  const location = useLocation();

  const { awayTeamRecord, homeTeamRecord, hasGameStarted } = location.state;

  const gameId = String(params.gid);
  const { gameData, isLoading: isGameDataLoading } = useGameData(gameId);
  const { previewData, isLoading: isPreviewLoading } = usePreviewData(gameId);
  const { width } = useWindowSize();
  const [index, setIndex] = useState(0);
  const [showStatOverview, setShowStatOverview] = useState(true);
  const tabs = [
    <BoxScore key={0} windowWidth={width} gameId={gameId} />,
    <PlayByPlay key={1} gameId={gameId} />,
    <TeamStats key={2} gameId={gameId} />,
  ];

  const changeIndex = (index: number) => setIndex(index);

  useEffect(() => {
    if (hasGameStarted) {
      document.title =
        isGameDataLoading || !gameData.awayTeam
          ? 'Boxscores'
          : `${teamIds[gameData.awayTeam.teamId][1]} vs. ${
              teamIds[gameData.homeTeam.teamId][1]
            }`;
    } else {
      document.title =
        isPreviewLoading || !previewData.awayTeam
          ? 'Boxscores'
          : `${teamIds[previewData.awayTeam.teamId][1]} vs. ${
              teamIds[previewData.homeTeam.teamId][1]
            }`;
    }
  });

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y > -180;
      if (isShow !== showStatOverview) setShowStatOverview(isShow);
    },
    [showStatOverview]
  );

  if (
    !isGameDataLoading &&
    Object.keys(gameData).length === 0 &&
    hasGameStarted
  )
    return (
      <Container margin>
        <BackButton />
        <h2>No game found</h2>
      </Container>
    );

  return (
    <GamePageContainer>
      {hasGameStarted && !isGameDataLoading && (
        <StatOverviewWrapper show={showStatOverview}>
          <Container margin>
            <StatOverview
              homeTeamRecord={homeTeamRecord}
              awayTeamRecord={awayTeamRecord}
              windowWidth={width}
              gameId={gameId}
              compact
            />
          </Container>
        </StatOverviewWrapper>
      )}
      <GameHeader>
        <Container margin>
          <BackButton />
        </Container>
        <StatOverview
          homeTeamRecord={homeTeamRecord}
          awayTeamRecord={awayTeamRecord}
          windowWidth={width}
          gameId={gameId}
          large={Boolean(!hasGameStarted)}
          pregame={!hasGameStarted}
        />
        <Tabs
          activeIndex={index}
          onChange={changeIndex}
          tabs={
            hasGameStarted ? ['Box Score', 'Play by Play', 'Team Stats'] : []
          }
        />
      </GameHeader>
      {hasGameStarted ? tabs[index] : <PreGamePreview gameId={gameId} />}
    </GamePageContainer>
  );
};

export default GamePage;
