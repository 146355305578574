import React from 'react';
import styled from 'styled-components';
import { GameProps } from '../types/common';
import { usePreviewData } from '../utils/fetchers';
import Grid from './Grid';
import Loader from './Loader';
import Matchup, { MatchupStatsContainer } from './Matchup';
import MatchupStat from './MatchupStat';
import TeamLeaders, { TeamLeadersContainer } from './TeamLeaders';
// import Container from './Container';

const LeadersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  flex: 1;
`;

const getPercentage = (decimal: number): number => {
  return decimal * 100;
};

const EmptyStateContainer = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.gray[5]};
`;

const PreGamePreview: React.FC<GameProps> = ({ gameId }) => {
  const { previewData, isLoading } = usePreviewData(gameId);

  if (isLoading)
    return (
      <Grid>
        <LeadersContainer>
          <TeamLeadersContainer>
            <Loader height={233} instances={1} radius={4} />
          </TeamLeadersContainer>
          <TeamLeadersContainer>
            <Loader height={233} instances={1} radius={4} />
          </TeamLeadersContainer>
        </LeadersContainer>
        <MatchupStatsContainer>
          <Loader height={528} instances={1} radius={4} />
        </MatchupStatsContainer>
      </Grid>
    );

  const awayTeamStats = previewData.pregameCharts.awayTeam.statistics;
  const homeTeamStats = previewData.pregameCharts.homeTeam.statistics;
  const awayTeamId = previewData.awayTeam.teamId;
  const homeTeamId = previewData.homeTeam.teamId;

  return (
    <Grid>
      <LeadersContainer>
        <TeamLeaders teamId={awayTeamId} statistics={awayTeamStats} />
        <Spacer />
        <TeamLeaders teamId={homeTeamId} statistics={homeTeamStats} />
      </LeadersContainer>
      <Matchup awayTeamId={awayTeamId} homeTeamId={homeTeamId}>
        {awayTeamStats && homeTeamStats ? (
          <>
            <MatchupStat
              label="offensive rating"
              awayTeamStat={awayTeamStats.points}
              homeTeamStat={homeTeamStats.points}
            />
            <MatchupStat
              label="fg%"
              awayTeamStat={awayTeamStats.fieldGoalsPercentage * 100}
              homeTeamStat={homeTeamStats.fieldGoalsPercentage * 100}
            />
            <MatchupStat
              label="points in the paint"
              awayTeamStat={awayTeamStats.pointsInThePaint}
              homeTeamStat={homeTeamStats.pointsInThePaint}
            />
            <MatchupStat
              label="fast break points"
              awayTeamStat={awayTeamStats.pointsFastBreak}
              homeTeamStat={homeTeamStats.pointsFastBreak}
            />
            <MatchupStat
              label="3pt%"
              awayTeamStat={getPercentage(
                awayTeamStats.threePointersPercentage
              )}
              homeTeamStat={getPercentage(
                homeTeamStats.threePointersPercentage
              )}
            />
            <MatchupStat
              label="FT%"
              awayTeamStat={getPercentage(awayTeamStats.freeThrowsPercentage)}
              homeTeamStat={getPercentage(homeTeamStats.freeThrowsPercentage)}
            />
            <MatchupStat
              label="assists per game"
              awayTeamStat={awayTeamStats.assists}
              homeTeamStat={homeTeamStats.assists}
            />
            <MatchupStat
              label="offensive rpg"
              awayTeamStat={awayTeamStats.reboundsTotal}
              homeTeamStat={homeTeamStats.reboundsTotal}
            />
            <MatchupStat
              label="steals per game"
              awayTeamStat={awayTeamStats.steals}
              homeTeamStat={homeTeamStats.steals}
            />
            <MatchupStat
              label="blocks per game"
              awayTeamStat={awayTeamStats.blocks}
              homeTeamStat={homeTeamStats.blocks}
            />
          </>
        ) : (
          <EmptyStateContainer>
            <span>No team stats yet!</span>
          </EmptyStateContainer>
        )}
      </Matchup>
    </Grid>
  );
};

export default PreGamePreview;
