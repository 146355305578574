import React from 'react';
import styled from 'styled-components';

const PlayerPhotoContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  overflow: hidden;
`;

interface PlayerPhotoProps {
  personId: number;
  firstName: string;
  lastName: string;
}

const PlayerPhoto: React.FC<PlayerPhotoProps> = ({
  personId,
  firstName,
  lastName,
}) => {
  return (
    <PlayerPhotoContainer>
      <img
        src={`https://cdn.nba.com/headshots/nba/latest/260x190/${personId}.png`}
        height={32}
        width={44}
        alt={`${firstName} ${lastName}`}
      />
    </PlayerPhotoContainer>
  );
};

export default PlayerPhoto;
