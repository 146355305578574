import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.gray[2]};
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: 12px;
  }
`;

export default CardContainer;
