import React from 'react';
import { Player } from '../types/game';
import { theme } from '../utils/theme';
import ScrollableTable from './ScrollableTable';
import StatRow from './StatRow';

interface TeamTableProps {
  windowWidth: number | undefined;
  activePlayers: Player[] | undefined;
}

const TeamTable: React.FC<TeamTableProps> = ({
  windowWidth,
  activePlayers,
}) => {
  const isMobile = Boolean(
    windowWidth && windowWidth < theme.viewports.mobile.max
  );

  return (
    <ScrollableTable
      keys={
        <>
          <thead>
            <tr>
              <th>Players</th>
            </tr>
          </thead>
          <tbody>
            {activePlayers &&
              activePlayers.map(
                (player) =>
                  player.status === 'ACTIVE' && (
                    <StatRow
                      key={player.personId}
                      player={player}
                      shortName
                      keyOnly
                    />
                  )
              )}
          </tbody>
        </>
      }
      values={
        <>
          <thead>
            <tr>
              <th>Player</th>
              <th>MIN</th>
              <th>FG</th>
              <th>3PT</th>
              <th>FT</th>
              <th>OREB</th>
              <th>DREB</th>
              <th>REB</th>
              <th>AST</th>
              <th>STL</th>
              <th>BLK</th>
              <th>TO</th>
              <th>PF</th>
              <th>+/-</th>
              <th>PTS</th>
            </tr>
          </thead>
          <tbody>
            {activePlayers &&
              activePlayers.map(
                (player) =>
                  player.status === 'ACTIVE' && (
                    <StatRow
                      key={player.personId}
                      player={player}
                      shortName={isMobile}
                    />
                  )
              )}
          </tbody>
        </>
      }
      windowWidth={windowWidth}
      desktopWidth={120}
      mobileWidth={112}
    />
  );
};

export default TeamTable;
