import styled from 'styled-components';

const Button = styled.button<{ outline?: boolean }>`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.blue[0]};
  color: ${(props) =>
    props.outline ? props.theme.colors.blue[0] : props.theme.colors.white};
  border-radius: 80px;
  padding: 8px 20px;
  font-size: ${(props) => props.theme.typography.medium.fontSize}px;
  line-height: ${(props) => props.theme.typography.medium.lineHeight}px;
  font-weight: 600;
  background: ${(props) =>
    props.outline ? props.theme.colors.white : props.theme.colors.blue[0]};
  transition: 0.2s ease all;
  :hover {
    color: ${(props) =>
      props.outline ? props.theme.colors.red : props.theme.colors.white};
    background: ${(props) =>
      props.outline ? props.theme.colors.white : props.theme.colors.blue[1]};
    border: 1px solid
      ${(props) =>
        props.outline ? props.theme.colors.red : props.theme.colors.blue[0]};
  }
`;

export default Button;
