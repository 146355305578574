import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyle from './components/GlobalStyle';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import PlayerPage from './pages/PlayerPage';
import ScrollToTop from './components/ScrollToTop';
import { ThemeProvider } from 'styled-components';
import { theme } from './utils/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/game/:gid" element={<GamePage />} />
            <Route path="/player/:pid" element={<PlayerPage />} />
          </Routes>
        </Router>
      </>
    </ThemeProvider>
  );
};

export default App;
