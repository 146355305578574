import React from 'react';
import styled from 'styled-components';
import TeamTable from './TeamTable';
import { useGameData } from '../utils/fetchers';
import { GameProps } from '../types/common';
import Loader from './Loader';
import TeamHeading from './TeamHeading';
import { teamIds } from '../utils/teamsData';
import Container from './Container';
import CardContainer from './CardContainer';

const TableContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  a {
    color: ${(props) => props.theme.colors.blue[0]};
    :hover {
      color: ${(props) => props.theme.colors.blue[1]};
    }
  }
  padding-bottom: 40px;
`;

const TeamContainer = styled.div`
  margin-top: 20px;
`;

interface BoxScoreProps extends GameProps {
  windowWidth: number | undefined;
}

const BoxScore: React.FC<BoxScoreProps> = ({ windowWidth, gameId }) => {
  const { gameData, isLoading } = useGameData(gameId);

  return (
    <TableContainer>
      <TeamContainer>
        <CardContainer>
          {isLoading ? (
            <Loader height={28} instances={12} radius={4} />
          ) : (
            <>
              <TeamHeading
                teamId={gameData.awayTeam.teamId}
                teamName={teamIds[gameData.awayTeam.teamId][0]}
              />
              <TeamTable
                windowWidth={windowWidth}
                activePlayers={gameData.awayTeam.players}
              />
            </>
          )}
        </CardContainer>
      </TeamContainer>
      <TeamContainer>
        <CardContainer>
          {isLoading ? (
            <Loader height={24} instances={12} radius={4} />
          ) : (
            <>
              <TeamHeading
                teamId={gameData.homeTeam.teamId}
                teamName={teamIds[gameData.homeTeam.teamId][0]}
              />
              <TeamTable
                windowWidth={windowWidth}
                activePlayers={gameData.homeTeam.players}
              />
            </>
          )}
        </CardContainer>
      </TeamContainer>
    </TableContainer>
  );
};

export default BoxScore;
