import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Button = styled.div`
  margin-top: 12px;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  outline: none;
  padding: 4px 0;
  align-items: center;
  max-width: 68px;
  img {
    margin-right: 4px;
  }
  h3 {
    margin-top: 0;
  }
`;

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(-1);
      }}
    >
      <img
        src="/assets/icons/arrow-left.svg"
        alt="Back Icon"
        width={16}
        height={16}
      />
      <h3>Back</h3>
    </Button>
  );
};

export default BackButton;
