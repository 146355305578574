import React from 'react';
import styled from 'styled-components';
import { SmallCapsText } from './Typography';
import { formatStat } from '../utils/dataParsers';

interface MatchupStatProps {
  label: string;
  awayTeamStat: number;
  homeTeamStat: number;
}

const Label = styled(SmallCapsText)`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.gray[5]};
`;

const MatchupStatContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const StatContainer = styled.div`
  display: flex;
  align-items: center;
  h3 {
    width: 36px;
    text-align: right;
    :first-child {
      text-align: left;
    }
  }
`;

const Meter = styled.div<{ percentage: number }>`
  margin-top: 8px;
  background: ${(props) => props.theme.colors.gray[3]};
  height: 4px;
  border-radius: 4px;
  span {
    display: block;
    background: ${(props) => props.theme.colors.blue[0]};
    border-radius: 4px;
    height: 100%;
    width: ${(props) => props.percentage}%;
    transition: 0.2s all ease;
  }
`;

const getPercentage = (awayTeam: number, homeTeam: number): number => {
  const percentage = Math.round((awayTeam / (awayTeam + homeTeam)) * 100);
  return isNaN(percentage) ? 50 : percentage;
};

const MatchupStat: React.FC<MatchupStatProps> = ({
  label,
  awayTeamStat,
  homeTeamStat,
}) => {
  return (
    <MatchupStatContainer>
      <StatContainer>
        <h3>{formatStat(String(awayTeamStat))}</h3>
        <Label>{label}</Label>
        <h3>{formatStat(String(homeTeamStat))}</h3>
      </StatContainer>
      <Meter percentage={getPercentage(awayTeamStat, homeTeamStat)}>
        <span />
      </Meter>
    </MatchupStatContainer>
  );
};

export default MatchupStat;
