import styled from 'styled-components';

export const MediumText = styled.span`
  font-size: ${(props) => props.theme.typography.medium.fontSize}px;
  line-height: ${(props) => props.theme.typography.medium.lineHeight}px;
`;

export const SmallText = styled.span`
  font-size: ${(props) => props.theme.typography.small.fontSize}px;
  line-height: ${(props) => props.theme.typography.small.lineHeight}px;
`;

export const SmallCapsText = styled.span`
  font-size: ${(props) => props.theme.typography.xsmall.fontSize}px;
  line-height: ${(props) => props.theme.typography.xsmall.lineHeight}px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
`;
