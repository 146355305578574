import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Player } from '../types/game';
import { parseMinutes, parsePlusMinus } from '../utils/dataParsers';

const InactiveRow = styled.td`
  text-align: center !important;
  color: ${(props) => props.theme.colors.gray[5]};
`;

interface RowProps {
  player: Player;
  keyOnly?: boolean;
  shortName?: boolean;
}

const StatRow: React.FC<RowProps> = ({ player, keyOnly, shortName }) => {
  return (
    <tr>
      <td>
        <Link to={`/player/${player.personId}`}>
          {shortName
            ? `${player.firstName[0]}. ${player.familyName}`
            : `${player.firstName} ${player.familyName}`}
        </Link>
      </td>
      {!keyOnly &&
        (parseMinutes(player.statistics.minutes) === '0' ? (
          <InactiveRow colSpan={14}>Has not entered game</InactiveRow>
        ) : (
          <>
            <td>{parseMinutes(player.statistics.minutes)}</td>
            <td>
              {player.statistics.fieldGoalsMade}-
              {player.statistics.fieldGoalsAttempted}
            </td>
            <td>
              {player.statistics.threePointersMade}-
              {player.statistics.threePointersAttempted}
            </td>
            <td>
              {player.statistics.freeThrowsMade}-
              {player.statistics.freeThrowsAttempted}
            </td>
            <td>{player.statistics.reboundsOffensive}</td>
            <td>{player.statistics.reboundsDefensive}</td>
            <td>{player.statistics.reboundsTotal}</td>
            <td>{player.statistics.assists}</td>
            <td>{player.statistics.steals}</td>
            <td>{player.statistics.blocks}</td>
            <td>{player.statistics.turnovers}</td>
            <td>{player.statistics.foulsPersonal}</td>
            <td>{parsePlusMinus(String(player.statistics.plusMinusPoints))}</td>
            <td>{player.statistics.points}</td>
          </>
        ))}
    </tr>
  );
};

export default StatRow;
