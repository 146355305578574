import React from 'react';
import { GameProps } from '../types/common';
import { useGameData } from '../utils/fetchers';
import Matchup from './Matchup';
import MatchupStat from './MatchupStat';

const MatchupgameData: React.FC<GameProps> = ({ gameId }) => {
  const { gameData } = useGameData(gameId);

  const toPercent = (stat: number): number => {
    return stat * 100;
  };

  // if (isLoading || !gameData) {
  //   return <></>;
  // }

  return (
    <Matchup
      awayTeamId={gameData.awayTeam.teamId}
      homeTeamId={gameData.homeTeam.teamId}
    >
      <MatchupStat
        label="fg%"
        awayTeamStat={toPercent(
          gameData.awayTeam.statistics.fieldGoalsPercentage
        )}
        homeTeamStat={toPercent(
          gameData.homeTeam.statistics.fieldGoalsPercentage
        )}
      />
      <MatchupStat
        label="3pt%"
        awayTeamStat={toPercent(
          gameData.awayTeam.statistics.threePointersPercentage
        )}
        homeTeamStat={toPercent(
          gameData.homeTeam.statistics.threePointersPercentage
        )}
      />
      <MatchupStat
        label="ft%"
        awayTeamStat={toPercent(
          gameData.awayTeam.statistics.freeThrowsPercentage
        )}
        homeTeamStat={toPercent(
          gameData.homeTeam.statistics.freeThrowsPercentage
        )}
      />
      <MatchupStat
        label="fta"
        awayTeamStat={gameData.awayTeam.statistics.freeThrowsAttempted}
        homeTeamStat={gameData.homeTeam.statistics.freeThrowsAttempted}
      />
      <MatchupStat
        label="rebounds"
        awayTeamStat={gameData.awayTeam.statistics.turnoversTotal}
        homeTeamStat={gameData.homeTeam.statistics.turnoversTotal}
      />
      <MatchupStat
        label="assists"
        awayTeamStat={gameData.awayTeam.statistics.assists}
        homeTeamStat={gameData.homeTeam.statistics.assists}
      />
      <MatchupStat
        label="steals"
        awayTeamStat={gameData.awayTeam.statistics.steals}
        homeTeamStat={gameData.homeTeam.statistics.steals}
      />
      <MatchupStat
        label="blocks"
        awayTeamStat={gameData.awayTeam.statistics.blocks}
        homeTeamStat={gameData.homeTeam.statistics.blocks}
      />
      <MatchupStat
        label="turnovers"
        awayTeamStat={gameData.awayTeam.statistics.turnovers}
        homeTeamStat={gameData.homeTeam.statistics.turnovers}
      />
      <MatchupStat
        label="personal fouls"
        awayTeamStat={gameData.awayTeam.statistics.foulsPersonal}
        homeTeamStat={gameData.homeTeam.statistics.foulsPersonal}
      />
    </Matchup>
  );
};

export default MatchupgameData;
