import styled from 'styled-components';

const Table = styled.table<{ desktopWidth?: number; mobileWidth?: number }>`
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  th {
    text-align: right;
    :first-child {
      text-align: left;
    }
  }
  tr {
    th {
      padding-left: 8px;
      font-weight: 600;
      font-size: ${(props) => props.theme.typography.small.fontSize}px;
      line-height: ${(props) => props.theme.typography.small.lineHeight}px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.gray[5]};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
      padding-bottom: 4px;
      :first-child {
        padding-left: 0;
      }
    }
    :last-child {
      td {
        border-bottom: none;
      }
    }
  }
  td {
    text-align: right;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: ${(props) => props.theme.typography.small.lineHeight}px;
    padding: 8px 0px 8px 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
    white-space: nowrap;
    :last-child {
      padding-right: 0;
    }
    :first-child {
      text-align: left;
      padding-left: 0;
      padding-right: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: ${(props) =>
        props.desktopWidth ? `${props.desktopWidth}px` : ''};
      max-width: ${(props) =>
        props.desktopWidth ? `${props.desktopWidth}px` : ''};
      @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
        min-width: ${(props) =>
          props.mobileWidth ? `${props.mobileWidth}px` : ''};
        max-width: ${(props) =>
          props.mobileWidth ? `${props.mobileWidth}px` : ''};
      }
    }
  }
`;

export default Table;
