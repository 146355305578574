export const teamIds = {
  1610612737: ['Atlanta Hawks', 'Hawks', 'ATL'],
  1610612751: ['Brooklyn Nets', 'Nets', 'BKN'],
  1610612738: ['Boston Celtics', 'Celtics', 'BOS'],
  1610612766: ['Charlotte Hornets', 'Hornets', 'CHA'],
  1610612741: ['Chicago Bulls', 'Bulls', 'CHI'],
  1610612739: ['Cleveland Cavaliers', 'Cavaliers', 'CLE'],
  1610612742: ['Dallas Mavericks', 'Mavericks', 'DAL'],
  1610612743: ['Denver Nuggets', 'Nuggets', 'DEN'],
  1610612765: ['Detroit Pistons', 'Pistons', 'DET'],
  1610612744: ['Golden State Warriors', 'Warriors', 'GSW'],
  1610612745: ['Houston Rockets', 'Rockets', 'HOU'],
  1610612754: ['Indiana Pacers', 'Pacers', 'IND'],
  1610612746: ['Los Angeles Clippers', 'Clippers', 'LAC'],
  1610612747: ['Los Angeles Lakers', 'Lakers', 'LAL'],
  1610612763: ['Memphis Grizzlies', 'Grizzlies', 'MEM'],
  1610612748: ['Miami Heat', 'Heat', 'MIA'],
  1610612749: ['Milwaukee Bucks', 'Bucks', 'MIL'],
  1610612750: ['Minnesota Timberwolves', 'Timberwolves', 'MIN'],
  1610612740: ['New Orleans Pelicans', 'Pelicans', 'NOP'],
  1610612752: ['New York Knicks', 'Knicks', 'NYK'],
  1610612760: ['Oklahoma City Thunder', 'Thunder', 'OKC'],
  1610612753: ['Orlando Magic', 'Magic', 'ORL'],
  1610612755: ['Philadelphia 76ers', '76ers', 'PHI'],
  1610612756: ['Phoenix Suns', 'Suns', 'PHX'],
  1610612757: ['Portland Trail Blazers', 'Trail Blazers', 'POR'],
  1610612758: ['Sacramento Kings', 'Kings', 'SAC'],
  1610612759: ['San Antonio Spurs', 'Spurs', 'SAS'],
  1610612761: ['Toronto Raptors', 'Raptors', 'TOR'],
  1610612762: ['Utah Jazz', 'Jazz', 'UTA'],
  1610612764: ['Washington Wizards', 'Wizards', 'WAS'],
  1610616833: ['East All-Stars', 'East', 'EST'],
  1610616834: ['West All-Stars', 'West', 'WST'],
} as { [teamId: string]: string[] };
