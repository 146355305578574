import React from 'react';
import styled from 'styled-components';
import { TeamStatistics } from '../types/preview';
import { teamIds } from '../utils/teamsData';
import CardContainer from './CardContainer';
import TeamHeading from './TeamHeading';
import TeamLeader from './TeamLeader';

interface TeamLeadersProps {
  teamId: number;
  statistics: TeamStatistics;
}

export const TeamLeadersContainer = styled(CardContainer)`
  padding-bottom: 20px;
  :first-child {
    margin-bottom: 20px;
  }
`;

const EmptyStateContainer = styled.div`
  text-align: center;
  padding: 106px 0;
  color: ${(props) => props.theme.colors.gray[5]};
`;

const TeamLeaders: React.FC<TeamLeadersProps> = ({ statistics, teamId }) => {
  return (
    <TeamLeadersContainer>
      <TeamHeading
        teamId={teamId}
        teamName={`${teamIds[teamId][1]} Season Leaders`}
      />
      {statistics.playerPtsLeaderPts ? (
        <>
          <TeamLeader
            info={statistics.playerPtsLeaderInfo}
            statLabel="points per game"
            statValue={statistics.playerPtsLeaderPts}
            round
          />
          <TeamLeader
            info={statistics.playerAstLeaderInfo}
            statLabel="assists per game"
            statValue={statistics.playerAstLeaderAst}
            round
          />
          <TeamLeader
            info={statistics.playerRebLeaderInfo}
            statLabel="rebounds per game"
            statValue={statistics.playerRebLeaderReb}
            round
          />
          <TeamLeader
            info={statistics.playerBlkLeaderInfo}
            statLabel="blocks per game"
            statValue={statistics.playerBlkLeaderBlk}
            round
          />
        </>
      ) : (
        <EmptyStateContainer>
          <span>No team leaders yet!</span>
        </EmptyStateContainer>
      )}
    </TeamLeadersContainer>
  );
};

export default TeamLeaders;
