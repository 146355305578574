import React from 'react';
import styled from 'styled-components';

const TeamContainer = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-top: 0;
    margin-left: 4px;
  }
`;

interface TeamHeadingProps {
  teamId: number;
  teamName: string;
}

const TeamHeading: React.FC<TeamHeadingProps> = ({ teamId, teamName }) => {
  return (
    <TeamContainer>
      <img
        src={`/assets/teams/${teamId}.svg`}
        width={20}
        height={20}
        alt={`${teamId} logo`}
        title={teamName}
      />
      <h3>{teamName}</h3>
    </TeamContainer>
  );
};

export default TeamHeading;
