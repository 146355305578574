import styled from 'styled-components';

const StatContainer = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  padding: ${(props) => (props.noPadding ? '0' : '0 12px 12px 12px')};
  div:last-child {
    min-width: 28px;
  }
`;

export default StatContainer;
