import React from 'react';
import styled, { keyframes } from 'styled-components';

interface SkeletonProps {
  height: number;
  radius: number;
}

interface LoaderProps extends SkeletonProps {
  instances: number;
}

const shine = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const Skeleton = styled.div<SkeletonProps>`
  position: relative;
  height: ${(props) => props.height}px;
  margin-top: 12px;
  :before {
    border-radius: ${(props) => props.radius}px;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    animation-name: ${shine};
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: ${(props) => `linear-gradient(
      to right,
      ${props.theme.colors.gray[1]} 0%,
      ${props.theme.colors.gray[2]} 50%,
      ${props.theme.colors.gray[1]} 100%
    )`};
    background-size: 50%;
  }
`;

const Loader: React.FC<LoaderProps> = ({ height, radius, instances }) => {
  return (
    <>
      {[...Array(instances)].map((skeleton, index) => (
        <Skeleton key={index} height={height} radius={radius} />
      ))}
    </>
  );
};

export default Loader;
