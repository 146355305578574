import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const TabContainer = styled.div`
  display: flex;
`;

const Tab = styled.div<{ isActive: boolean }>`
  font-weight: 600;
  padding: 12px 0;
  margin: 0 12px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.isActive ? `2px solid ${props.theme.colors.blue[0]}` : ''};
  color: ${(props) =>
    props.isActive ? props.theme.colors.blue[0] : props.theme.colors.black};
  transition: 0.2s color ease;
  :first-child {
    margin-left: 0;
  }
`;

interface TabsProps {
  activeIndex: number;
  tabs: string[];
  onChange: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ activeIndex, tabs, onChange }) => {
  return (
    <Container margin>
      <TabContainer>
        {tabs.map((tab, tabIndex) => (
          <Tab
            key={tabIndex}
            isActive={tabIndex === activeIndex}
            onClick={() => onChange(tabIndex)}
          >
            {tab}
          </Tab>
        ))}
      </TabContainer>
    </Container>
  );
};

export default Tabs;
