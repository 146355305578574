export const getNBADate = (date: Date, offset?: number): string => {
  date.setDate(date.getDate() + (offset ? offset : 0));
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  );
};

export const formatGameTime = (time: string): string => {
  const date = new Date(time);
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getDateObject = (date: string): Date => {
  const year = Number(date.slice(0, 4));
  const month = Number(date.slice(5, 7)) - 1; // subtract 1 to normalize
  const day = Number(date.slice(8));
  return new Date(year, month, day);
};

export const formatDate = (date: string): string => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const today = new Date();
  const inputDate = getDateObject(date);

  const utc1 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const utc2 = Date.UTC(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );
  const difference = Math.floor((utc2 - utc1) / _MS_PER_DAY);

  switch (difference) {
    case -1:
      return 'Yesterday';
    case 0:
      return 'Today';
    case 1:
      return 'Tomorrow';
    default:
      return `${
        inputDate.getMonth() + 1
      }/${inputDate.getDate()}/${inputDate.getFullYear()}`;
  }
};

export const getSeasonYear = (): number => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  return month > 6 ? year : year - 1; // may have to change to 5 depending on NBA schedule for future seasons
};
