import styled from 'styled-components';
import Container from './Container';

const Grid = styled(Container)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  padding-bottom: 40px;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export default Grid;
