import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getNBADate, formatDate, getDateObject } from '../utils/dateTime';
import { useGames } from '../utils/fetchers';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { generateTeamPlayerMap } from '../utils/dataParsers';
import GamePreviewCard from '../components/GamePreview';
import { GamePreview } from '../types/scoreboard';
import Loader from '../components/Loader';
import { SmallText } from '../components/Typography';
import Container from '../components/Container';
import { TeamPlayerMap } from '../types/common';

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const NavContainer = styled(Container)`
  margin-top: 12px;
  margin-bottom: 4px;
  display: flex;
`;

const GamesContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const DateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const GameCount = styled(SmallText)`
  text-align: center;
  color: ${(props) => props.theme.colors.gray[5]};
`;

const DateLabel = styled.h3`
  margin-top: 0;
  text-align: center;
`;

interface GamePlayerMap {
  [game: string]: string[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const HomePage: React.FC = () => {
  const query = useQuery();
  const date = query.get('date');
  const { games, isLoading } = useGames(date || getNBADate(new Date()));
  const [gamePlayerMap, setGamePlayerMap] = useState<GamePlayerMap>({});
  const [teamPlayerMap] = useLocalStorage<TeamPlayerMap>(
    'teamPlayerMap',
    generateTeamPlayerMap()
  );
  const navigate = useNavigate();

  const updateDate = (offset: number) => {
    navigate({
      search: new URLSearchParams({
        date: getNBADate(date ? getDateObject(date) : new Date(), offset),
      }).toString(),
    });
  };

  useEffect(() => {
    document.title = 'Boxscores';
    const newGamePlayerMap = {} as GamePlayerMap;
    games &&
      games.forEach((game: GamePreview) => {
        newGamePlayerMap[game.gameId] = teamPlayerMap[
          game.awayTeam.teamId
        ].concat(teamPlayerMap[game.homeTeam.teamId]);
      });
    setGamePlayerMap(newGamePlayerMap);
  }, [games, teamPlayerMap]);

  return (
    <>
      <NavContainer small margin>
        <Button
          onClick={() => {
            updateDate(-1);
          }}
        >
          <img
            src="/assets/icons/chevron-left.svg"
            width={24}
            height={24}
            alt="previous"
          />
        </Button>
        <DateContainer>
          <DateLabel>{formatDate(date || getNBADate(new Date()))}</DateLabel>
          <GameCount>{games && games.length} games</GameCount>
        </DateContainer>
        <Button
          onClick={() => {
            updateDate(1);
          }}
        >
          <img
            src="/assets/icons/chevron-right.svg"
            width={24}
            height={24}
            alt="next"
          />
        </Button>
      </NavContainer>
      <GamesContainer small margin>
        {isLoading ? (
          <>
            <Loader height={130} instances={5} radius={12} />
          </>
        ) : (
          games.map((game: GamePreview) => (
            <GamePreviewCard
              key={game.gameId}
              gameId={game.gameId}
              gameData={game}
              players={gamePlayerMap[game.gameId]}
            />
          ))
        )}
      </GamesContainer>
    </>
  );
};

export default HomePage;
