import React from 'react';
import styled from 'styled-components';
import { SmallText } from './Typography';

const TeamPreviewContainer = styled.div<{
  isHome: boolean;
  isLoser: boolean;
  horizontal?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.horizontal ? (props.isHome ? 'row-reverse' : 'row') : 'column'};
  align-items: ${(props) =>
    props.horizontal ? 'center' : props.isHome ? 'flex-end' : 'flex-start'};
  width: 100px;
  white-space: nowrap;
  margin-top: 12px;
  margin-bottom: 16px;
  h1,
  h2,
  h3,
  span {
    color: ${(props) => (props.isLoser ? props.theme.colors.gray[4] : '')};
  }
  text-align: ${(props) => (props.isHome ? 'right' : 'left')};
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    width: 84px;
  }
`;

const TeamDataContainer = styled.div<{
  horizontal?: boolean;
  isHome?: boolean;
}>`
  display: ${(props) => (props.horizontal ? 'flex' : '')};
  flex-direction: ${(props) => (props.horizontal ? 'column' : '')};
  margin-left: ${(props) => (props.horizontal && !props.isHome ? '8px' : '')};
  margin-right: ${(props) => (props.horizontal && props.isHome ? '8px' : '')};
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    margin-left: ${(props) => (props.horizontal && !props.isHome ? '4px' : '')};
    margin-right: ${(props) => (props.horizontal && props.isHome ? '4px' : '')};
  }
`;

const TeamRecordLabel = styled(SmallText)`
  color: ${(props) => props.theme.colors.gray[5]};
`;

export interface TeamPreviewProps {
  teamId: number;
  teamName: string;
  teamRecord: string;
  isHome: boolean;
  isLoser: boolean;
  horizontal?: boolean;
  isMobile?: boolean;
}

const TeamPreview: React.FC<TeamPreviewProps> = ({
  teamId,
  teamName,
  teamRecord,
  isHome,
  isLoser,
  horizontal,
  isMobile,
}) => {
  const teamLabel =
    horizontal && !isMobile ? <h2>{teamName}</h2> : <h3>{teamName}</h3>;
  return (
    <TeamPreviewContainer
      isHome={isHome}
      isLoser={isLoser}
      horizontal={horizontal}
    >
      <img
        src={`/assets/teams/${teamId}.svg`}
        width={horizontal ? 40 : 32}
        height={horizontal ? 40 : 32}
        alt={`${teamName} logo`}
      />
      <TeamDataContainer horizontal={horizontal} isHome={isHome}>
        {teamLabel}
        <TeamRecordLabel>{teamRecord}</TeamRecordLabel>
      </TeamDataContainer>
    </TeamPreviewContainer>
  );
};

export default TeamPreview;
