import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Information } from '../types/player';
import { formatPlayerInfo } from '../utils/dataParsers';
import PlayerPhoto from './PlayerPhoto';
import { SmallCapsText, SmallText } from './Typography';

interface PregameStatsProps {
  info: Information;
  statLabel: string;
  statValue: number;
  round?: boolean;
}

const Label = styled(SmallCapsText)`
  color: ${(props) => props.theme.colors.gray[5]};
  white-space: nowrap;
`;

const LeaderContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin: 0 12px;
  flex: 1;
  h3 {
    white-space: nowrap;
    transition: 0.2s color ease;
  }
  span {
    color: ${(props) => props.theme.colors.gray[5]};
  }
  :hover {
    h3 {
      color: ${(props) => props.theme.colors.blue[0]};
    }
  }
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TeamLeader: React.FC<PregameStatsProps> = ({
  info,
  statLabel,
  statValue,
  round,
}) => {
  const { personId, firstName, lastName } = info;

  return (
    <LeaderContainer>
      <Link to={`/player/${personId}`}>
        <PlayerContainer>
          <PlayerPhoto
            personId={Number(personId)}
            firstName={firstName}
            lastName={lastName}
          />
          <TextWrapper>
            <h3>
              {firstName} {lastName}
            </h3>
            <SmallText>{formatPlayerInfo(info)}</SmallText>
          </TextWrapper>
          <StatContainer>
            <Label>{statLabel}</Label>
            <h3>{round ? statValue.toFixed(1) : statValue}</h3>
          </StatContainer>
        </PlayerContainer>
      </Link>
    </LeaderContainer>
  );
};

export default TeamLeader;
