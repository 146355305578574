import React from 'react';
import Loader from './Loader';
import { GameProps } from '../types/common';
import { useGameData } from '../utils/fetchers';
import TeamStat from './TeamStat';
import styled from 'styled-components';
import Container from './Container';
import MatchupStats from './MatchupStats';
import { teamIds } from '../utils/teamsData';
import CardContainer from './CardContainer';
import Grid from './Grid';
import TeamLeader from './TeamLeader';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const GameLeadersContainer = styled(CardContainer)`
  margin-top: 20px;
  padding-bottom: 20px;
`;

const GameHeaderContainer = styled.div`
  h3 {
    margin-top: 0;
  }
`;

const TeamStats: React.FC<GameProps> = ({ gameId }) => {
  const { gameData, isLoading } = useGameData(gameId);

  if (isLoading || !gameData)
    return (
      <Container>
        <GameLeadersContainer>
          <Loader height={28} instances={8} radius={4} />
        </GameLeadersContainer>
      </Container>
    );

  const { gameLeaders } = gameData;

  return (
    <Grid>
      <Column>
        <TeamStat
          stats={gameData.awayTeam.statistics}
          id={gameData.awayTeam.teamId}
          name={teamIds[gameData.awayTeam.teamId][0]}
        />
        <TeamStat
          stats={gameData.homeTeam.statistics}
          id={gameData.homeTeam.teamId}
          name={teamIds[gameData.homeTeam.teamId][0]}
        />
        <GameLeadersContainer>
          <GameHeaderContainer>
            <h3>Game Leaders</h3>
          </GameHeaderContainer>
          <TeamLeader
            info={gameLeaders.points.playerInfo}
            statLabel="points"
            statValue={gameLeaders.points.stat}
          />
          <TeamLeader
            info={gameLeaders.assists.playerInfo}
            statLabel="assists"
            statValue={gameLeaders.assists.stat}
          />
          <TeamLeader
            info={gameLeaders.rebounds.playerInfo}
            statLabel="rebounds"
            statValue={gameLeaders.rebounds.stat}
          />
        </GameLeadersContainer>
      </Column>
      <MatchupStats gameId={gameId} />
    </Grid>
  );
};

export default TeamStats;
