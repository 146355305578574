export const theme = {
  typography: {
    xlarge: {
      fontSize: 28,
      lineHeight: 32,
    },
    large: {
      fontSize: 18,
      lineHeight: 28,
    },
    medium: {
      fontSize: 14,
      lineHeight: 20,
    },
    small: {
      fontSize: 12,
      lineHeight: 16,
    },
    xsmall: {
      fontSize: 10,
      lineHeight: 12,
    },
  },
  colors: {
    black: '#191b1e',
    white: '#ffffff',
    gray: {
      0: '#fcfcfc',
      1: '#f4f4f5',
      2: '#e7e8e9',
      3: '#c3c4c7',
      4: '#929498',
      5: '#616367',
    },
    blue: { 0: '#1d428a', 1: '#0a2c6f' },
    red: '#bb344c',
    green: '#289700',
  },
  sizes: {
    maxWidthSmall: 456,
    maxWidthLarge: 1024,
  },
  viewports: {
    mobile: {
      min: 0,
      max: 799,
    },
    tablet: {
      min: 800,
      max: 1023,
    },
    desktop: {
      min: 1024,
      max: 1215,
    },
  },
};

export type Theme = typeof theme;
