import React from 'react';
import styled from 'styled-components';
import { Period } from '../types/game';

interface LineScoreRowProps {
  teamName: string;
  lineScore: Period[];
}

const Highlight = styled.td`
  color: ${(props) => props.theme.colors.black};
`;

const LineScoreRow: React.FC<LineScoreRowProps> = ({ teamName, lineScore }) => {
  return (
    <tr>
      <Highlight>{teamName}</Highlight>
      {lineScore.map((quarter, index) => (
        <td key={index}>{quarter.score}</td>
      ))}
      <Highlight>
        {lineScore.reduce(
          (acc: number, quarter: Period) => acc + Number(quarter.score),
          0
        )}
      </Highlight>
    </tr>
  );
};

export default LineScoreRow;
