import React from 'react';
import styled from 'styled-components';
import { TeamStatistics } from '../types/game';
import CardContainer from './CardContainer';
import Stat from './Stat';
import TeamHeading from './TeamHeading';

interface TeamStatProps {
  stats: TeamStatistics;
  id: number;
  name: string;
}

const TeamCardContainer = styled(CardContainer)`
  :first-child {
    margin-bottom: 20px;
  }
  padding-bottom: 20px;
`;

const TeamStatContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 16px;
`;

const TeamStat: React.FC<TeamStatProps> = ({ stats, id, name }) => {
  return (
    <TeamCardContainer>
      <TeamHeading teamId={id} teamName={name} />
      <TeamStatContainer>
        <Stat label="largest lead" value={stats.biggestLead} />
        <Stat label="fast break pts" value={stats.fastBreakPointsMade} />
        <Stat label="longest run" value={stats.biggestScoringRun} />
        <Stat label="pts in paint" value={stats.pointsInThePaint} />
        <Stat label="pts off turnovers" value={stats.pointsFromTurnovers} />
        <Stat label="2nd chance points" value={stats.pointsSecondChance} />
      </TeamStatContainer>
    </TeamCardContainer>
  );
};

export default TeamStat;
