import React from 'react';
import styled from 'styled-components';
import { teamIds } from '../utils/teamsData';
import ScrollableTable from './ScrollableTable';

interface TeamTableProps {
  windowWidth: number | undefined;
  keys: string[];
  values: (string | number)[][];
}

const SeasonRow = styled.tr`
  th:nth-child(2) {
    text-align: left;
    padding-left: 0;
  }
`;

const SeasonStatRow = styled.tr`
  td:nth-child(2) {
    text-align: left;
    padding: 8px 16px 8px 0;
  }
`;

const TeamName = styled.span`
  margin-left: 4px;
`;

const SeasonTable: React.FC<TeamTableProps> = ({
  windowWidth,
  keys,
  values,
}) => {
  return (
    <ScrollableTable
      keys={
        <>
          <thead>
            <SeasonRow>
              <th>Season</th>
              <th>Team</th>
            </SeasonRow>
          </thead>
          <tbody>
            {values.map((value, index) => (
              <SeasonStatRow key={index}>
                {value.map((stat, index) =>
                  index === 0 ? (
                    <td key={index}>{stat}</td>
                  ) : (
                    index === 1 && (
                      <td key={index}>
                        <img
                          src={`/assets/teams/${stat}.svg`}
                          alt={String(stat)}
                          width={20}
                          height={20}
                        />
                        <TeamName>{teamIds[stat][2]}</TeamName>
                      </td>
                    )
                  )
                )}
              </SeasonStatRow>
            ))}
          </tbody>
        </>
      }
      values={
        <>
          <thead>
            <SeasonRow>
              {keys.map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </SeasonRow>
          </thead>
          <tbody>
            {values.map((value, index) => (
              <SeasonStatRow key={index}>
                {value.map((stat, index) => (
                  <td key={index}>
                    {index === 1 ? (
                      <>
                        <img
                          src={`/assets/teams/${stat}.svg`}
                          alt={String(stat)}
                          width={20}
                          height={20}
                        />
                        <TeamName>{teamIds[stat][2]}</TeamName>
                      </>
                    ) : (
                      stat
                    )}
                  </td>
                ))}
              </SeasonStatRow>
            ))}
          </tbody>
        </>
      }
      windowWidth={windowWidth}
      desktopWidth={68}
      mobileWidth={68}
    />
  );
};

export default SeasonTable;
