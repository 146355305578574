import React from 'react';
import styled from 'styled-components';
import { teamIds } from '../utils/teamsData';
import CardContainer from './CardContainer';

export const MatchupStatsContainer = styled(CardContainer)`
  margin-left: 20px;
  padding-bottom: 24px;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

interface MatchupProps {
  awayTeamId: number;
  homeTeamId: number;
}

const Matchup: React.FC<MatchupProps> = ({
  awayTeamId,
  homeTeamId,
  children,
}) => {
  return (
    <MatchupStatsContainer>
      <Header>
        <img
          src={`/assets/teams/${awayTeamId}.svg`}
          width={20}
          height={20}
          alt={`${teamIds[awayTeamId][0]} logo`}
        />
        <Spacer />
        <img
          src={`/assets/teams/${homeTeamId}.svg`}
          width={20}
          height={20}
          alt={`${teamIds[homeTeamId][0]} logo`}
        />
      </Header>
      {children}
    </MatchupStatsContainer>
  );
};

export default Matchup;
