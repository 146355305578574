import React from 'react';
import styled from 'styled-components';
import { Period } from '../types/game';
import { parseQuarter } from '../utils/dataParsers';
import LineScoreRow from './LineScoreRow';

interface ScoreTableProps {
  awayTeamName: string;
  awayTeamLineScore: Period[];
  homeTeamName: string;
  homeTeamLineScore: Period[];
}

const LinescoreTable = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 12px;
  margin-bottom: 16px;
  font-size: ${(props) => props.theme.typography.small.fontSize}px;
  line-height: ${(props) => props.theme.typography.small.lineHeight}px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.gray[5]};
  th {
    padding-bottom: 1px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
    :first-child {
      min-width: 32px;
    }
  }
  td,
  th {
    text-align: left;
    font-weight: normal;
    padding-right: 12px;
    :last-child {
      white-space: nowrap;
      padding-right: 0;
    }
  }
  tbody {
    tr {
      td {
        padding-top: 1px;
        :first-child {
          width: 100%;
        }
      }
      :first-child {
        td {
          padding-top: 4px;
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    margin-top: 4px;
    td {
      padding-right: 16px;
    }
  }
`;

const Highlight = styled.th`
  color: ${(props) => props.theme.colors.black};
`;

const ScoreTable: React.FC<ScoreTableProps> = ({
  awayTeamName,
  awayTeamLineScore,
  homeTeamName,
  homeTeamLineScore,
}) => {
  return (
    <LinescoreTable cellPadding={0}>
      <thead>
        <tr>
          <th></th>
          {homeTeamLineScore.map((score, quarter) => (
            <th key={quarter}>
              {quarter < 4 ? quarter + 1 : parseQuarter(quarter + 1)}
            </th>
          ))}
          <Highlight>T</Highlight>
        </tr>
      </thead>
      <tbody>
        <LineScoreRow teamName={awayTeamName} lineScore={awayTeamLineScore} />
        <LineScoreRow teamName={homeTeamName} lineScore={homeTeamLineScore} />
      </tbody>
    </LinescoreTable>
  );
};

export default ScoreTable;
