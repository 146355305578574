import React from 'react';
import { Link } from 'react-router-dom';
import PlayerPreview from './PlayerPreview';
// import { GamePreview } from '../types/scoreboard';
import styled from 'styled-components';
import TeamPreview from './TeamPreview';
import { GameProps } from '../types/common';
import { GameStatus, ScoreWrapper, Score, Dash } from './Score';
import {
  getTeamRecord,
  hasGameEnded,
  hasGameStarted,
  parseGamePreviewStatus,
} from '../utils/dataParsers';
import { teamIds } from '../utils/teamsData';
import { GamePreview } from '../types/scoreboard';

const GamePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.colors.gray[2]};
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 12px;
  overflow: hidden;
  a {
    width: 100%;
  }
  a:active {
    background: ${(props) => props.theme.colors.gray[0]};
  }
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled(ScoreWrapper)`
  margin-top: 20px;
`;

interface GamePreviewProps extends GameProps {
  players: string[];
  gameData: GamePreview;
}

const GamePreviewCard: React.FC<GamePreviewProps> = ({
  gameData,
  gameId,
  players,
}) => {
  const isVisitorLoser =
    hasGameEnded(gameData) &&
    Number(gameData.awayTeam.score) < Number(gameData.homeTeam.score);

  const isHomeLoser =
    hasGameEnded(gameData) &&
    Number(gameData.awayTeam.score) > Number(gameData.homeTeam.score);

  return (
    <GamePreviewContainer>
      <Link
        to={`/game/${gameId}`}
        state={{
          awayTeamRecord: getTeamRecord(gameData, false),
          homeTeamRecord: getTeamRecord(gameData, true),
          hasGameStarted: hasGameStarted(gameData),
        }}
      >
        <ScoreContainer>
          <GameStatus
            isLive={hasGameStarted(gameData) && !hasGameEnded(gameData)}
          >
            {parseGamePreviewStatus(gameData)}
          </GameStatus>
          <TeamContainer>
            <TeamPreview
              teamId={gameData.awayTeam.teamId}
              teamName={teamIds[gameData.awayTeam.teamId][1]}
              teamRecord={getTeamRecord(gameData, false)}
              isHome={false}
              isLoser={isVisitorLoser}
            />
            <Wrapper>
              <Score isLoser={isVisitorLoser}>
                {hasGameStarted(gameData) && gameData.awayTeam.score}
              </Score>
              <Dash>{hasGameStarted(gameData) ? '-' : ''}</Dash>
              <Score isLoser={isHomeLoser}>
                {hasGameStarted(gameData) && gameData.homeTeam.score}
              </Score>
            </Wrapper>
            <TeamPreview
              teamId={gameData.homeTeam.teamId}
              teamName={teamIds[gameData.homeTeam.teamId][1]}
              teamRecord={getTeamRecord(gameData, true)}
              isHome={true}
              isLoser={isHomeLoser}
            />
          </TeamContainer>
        </ScoreContainer>
      </Link>

      {players &&
        hasGameStarted(gameData) &&
        players.map((player: string) => (
          <PlayerPreview
            key={player}
            personId={Number(player)}
            gameId={gameId}
            displayIsOnCourt={
              hasGameStarted(gameData) && !hasGameEnded(gameData)
            }
          />
        ))}
    </GamePreviewContainer>
  );
};

export default GamePreviewCard;
