import useSWR from 'swr';
import axios from 'axios';
import { getSeasonYear } from './dateTime';
import { Game } from '../types/game';
import { GamePreview } from '../types/scoreboard';
import { Preview } from '../types/preview';
import { Play } from '../types/play';
import { PlayerProfile } from '../types/player';

const base = '/api';
const refreshRate = 1000 * 5;
const season = getSeasonYear();
const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const useGames = (
  date: string
): {
  games: GamePreview[];
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, error } = useSWR<GamePreview[]>(
    `${base}/scoreboard?date=${date}`,
    fetcher
  );

  return {
    games: (data && data) || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const useGameData = (
  gameId: string
): {
  gameData: Game;
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, error } = useSWR<Game>(
    `${base}/boxscore?gameId=${gameId}`,
    fetcher,
    {
      refreshInterval: refreshRate,
    }
  );

  return {
    gameData: (data && data) || ({} as Game),
    isLoading: !error && !data,
    isError: error,
  };
};

export const usePreviewData = (
  gameId: string
): {
  previewData: Preview;
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, error } = useSWR<Preview>(
    `${base}/preview?gameId=${gameId}`,
    fetcher,
    {
      refreshInterval: refreshRate,
    }
  );

  return {
    previewData: (data && data) || ({} as Preview),
    isLoading: !error && !data,
    isError: error,
  };
};

export const usePlays = (
  gameId: string
): {
  plays: Play[];
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, error } = useSWR<Play[]>(
    `${base}/pbp?gameId=${gameId}`,
    fetcher,
    {
      refreshInterval: refreshRate,
    }
  );

  return {
    plays: (data && data) || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const usePlayer = (
  playerId: string
): {
  playerProfile: PlayerProfile;
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, error } = useSWR<PlayerProfile>(
    `${base}/player?playerId=${playerId}&season=${season}`,
    fetcher
  );

  return {
    playerProfile: (data && data) || ({} as PlayerProfile),
    isLoading: !error && !data,
    isError: error,
  };
};
