import React from 'react';
import styled from 'styled-components';
import { Play } from '../types/play';
import { parseTimeStamp } from '../utils/dataParsers';
import { teamIds } from '../utils/teamsData';

const PlayContainer = styled.tr<{ madeShot: boolean }>`
  font-weight: ${(props) => (props.madeShot ? 700 : 400)};
  line-height: 24px;
  td {
    font-feature-settings: 'tnum' on, 'lnum' on;
    :last-child {
      text-align: right;
    }
  }
`;

const Description = styled.td`
  flex: 1;
`;

interface PlayProps {
  defaultTeamId: number;
  play: Play;
}

const PlayRow: React.FC<PlayProps> = ({ defaultTeamId, play }) => {
  const teamId = play.teamId ? play.teamId : defaultTeamId;

  return (
    <PlayContainer madeShot={play.description.includes('PTS)')}>
      <td>{parseTimeStamp(play.clock)}</td>
      <td>
        <img
          src={`/assets/teams/${teamId}.svg`}
          width={20}
          height={20}
          alt={`${teamIds[teamId][0]} logo`}
          title={teamIds[teamId][0]}
        />
      </td>
      <Description>{play.description}</Description>
      {/* <td>
        {play.awayTeamScore} - {play.homeTeamScore}
      </td> */}
    </PlayContainer>
  );
};

export default PlayRow;
