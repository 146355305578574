import React, { useState } from 'react';
import styled from 'styled-components';
import PlayRow from './PlayRow';
import { useGameData } from '../utils/fetchers';
import { GameProps } from '../types/common';
import { Play } from '../types/play';
import Table from './Table';
import { parseQuarterFull } from '../utils/dataParsers';

const Plays = styled(Table)`
  margin-top: 4px;
  td {
    padding-left: 0;
    line-height: 24px;
    text-align: left;
    white-space: normal;
    :first-child {
      width: 60px;
      @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
        width: 40px;
      }
    }
    :last-child {
      text-align: left;
    }
  }
  thead {
    tr {
      th {
        padding-left: 0;
        text-align: left;
        @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
          padding-right: 16px;
        }
      }
    }
  }
`;

const PlaysContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
  :last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const PlayHeaderContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  h3 {
    margin-top: 0;
    margin-right: 8px;
  }
  img {
    transition: transform 0.2s;
    transform: ${(props) => (props.isExpanded ? '' : 'rotate(180deg)')};
  }
`;

interface PlaysTableProps extends GameProps {
  period: number;
  plays: Play[];
}

const PlaysTable: React.FC<PlaysTableProps> = ({ gameId, plays, period }) => {
  const { gameData } = useGameData(gameId);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <PlaysContainer>
      <PlayHeaderContainer
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3>{parseQuarterFull(period)}</h3>
        <img src="/assets/icons/chevron-up.svg" alt="chevron" />
      </PlayHeaderContainer>
      {isExpanded && plays && (
        <Plays cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Team</th>
              <th>Play</th>
              {/* <th>Score</th> */}
            </tr>
          </thead>
          <tbody>
            {plays.map((play: Play, index) => (
              <PlayRow
                defaultTeamId={gameData.homeTeam.teamId}
                key={index}
                play={play}
              />
            ))}
          </tbody>
        </Plays>
      )}
    </PlaysContainer>
  );
};

export default PlaysTable;
