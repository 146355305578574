import React from 'react';
import styled from 'styled-components';
import { SmallCapsText } from './Typography';

const StatLabel = styled(SmallCapsText)`
  color: ${(props) => props.theme.colors.gray[5]};
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40px;
`;

const StatValue = styled.h3`
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

interface StatProps {
  label: string;
  value: string | number;
}

const Stat: React.FC<StatProps> = ({ label, value }) => {
  return (
    <StatContainer>
      <StatLabel>{label}</StatLabel>
      <StatValue>{value}</StatValue>
    </StatContainer>
  );
};

export default Stat;
